<template>
  <section>
    <v-container>
      <v-row
        class="pt-15"
        justify="center"
        align="center"
      >
        <v-col
          cols="11"
          md="8"
          lg="8"
          xl="5"
        >
          <kn-form-title
            :title="routerName"
            :routerName="routerName"
          />
          <v-row justify="start">
            <v-col
              cols="12"
              md="4"
              lg="4"
              xl="4"
            >
              <v-btn
                outlined
                tile
                block
                color="primary"
                @click="valueDeterminate = 100"
              >
                {{ title }}
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-progress-linear
                v-model="valueDeterminate"
                color="primary"
                rounded
                height="8"
              ></v-progress-linear>
            </v-col>
          </v-row>
          <kn-form-note-of-mandatory />
          
          <div v-if="valueDeterminate === 100">
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col
                  class="pb-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Fecha:</v-subheader>
                </v-col>
                <v-col
                  class="pb-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-text-field
                    v-model="incomeDate"
                    dense
                    outlined
                    type="date"
                    hint="DD / MM / YYYY"
                    persistent-hint
                    :disabled="readonly"
                  >
                    <template v-slot:append-outer>
                      <v-icon color="primary">mdi-calendar-month-outline</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>Alumno:</v-subheader>
                </v-col>
                <v-col class="py-0">
                  <v-autocomplete
                    v-model="income.id_alumno"
                    dense
                    outlined
                    :disabled="readonly"
                    :items="activeItems(students)"
                    item-value="id"
                    item-text="datos_personales.primer_nombre"
                    @change="getDebts('pendiente de pago')"
                  >
                    <template #item="{ item }">
                      <p>{{ fullName(item.datos_personales) }}</p>
                    </template>
                    <template #selection="{ item }">
                      {{ fullName(item.datos_personales) }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <!-- <kn-text-field
                label="RFC:"
                v-model="income.rfc"
                :rules="[]"
              /> -->
              <kn-select
                v-model="income.id_categoria"
                label="Concepto:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="activeItems(incomeCategories)"
                item-value="id"
                item-text="dato"
              />
              <v-row v-if="showDebts">
                <v-col
                  class="py-0"
                  cols="12"
                  md="4"
                  lg="4"
                  xl="4"
                >
                  <v-subheader>
                    {{ debtText }}<span class="error--text">*</span>
                  </v-subheader>
                </v-col>
                <v-col class="py-0">
                  <v-select
                    v-model="debtsSelected"
                    dense
                    outlined
                    multiple
                    chips
                    :rules="[rules.required]"
                    :disabled="readonly"
                    :items="activeItems(studentDebts)"
                    item-value="id"
                    @change="fillDebtValues"
                  >
                    <template #selection="{attrs, selected, item, select}">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        outlined
                        color="blue"
                        small
                        @click="select"
                      >
                        {{ item.nombre_adeudo ? item.nombre_adeudo : item.comentarios }} - 
                        <span :class="`${colorItem(item)}--text`">Vencimiento: {{ item.fecha_vencimiento }}</span> -
                        $ {{ item.total_adeudo }}
                      </v-chip>
                    </template>
                    <template #item="{ attrs, on, item }">
                      <v-list-item
                        v-bind="attrs"
                        v-on="on"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-action>
                            <v-checkbox :input-value="active"></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> {{ item.nombre_adeudo ? item.nombre_adeudo : item.comentarios }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <span :class="`${colorItem(item)}--text`">Vencimiento: {{ item.fecha_vencimiento }}</span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            ${{ item.total_adeudo }}
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                      <!-- {{ item.nombre_adeudo }} - <span :class="`${colorItem(item)}--text`">Vencimiento: {{ item.fecha_vencimiento }}</span> -->
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <kn-text-field
                v-model.number="income.sub_total"
                label="Subtotal:"
                :rules="[rules.required]"
                :disabled="readonly"
                @input="calculateTotalSimple"
              />
              <kn-text-field
                v-model.number="income.total_impuestos"
                label="Total impuesto:"
                :rules="mandatoryTaxesRules"
                :disabled="readonly"
                :isMandatory="mandatoryTaxesRules.length > 0"
                :suffix="taxSuffix"
                @input="calculateTotalSimple"
              />
              <kn-text-field
                v-model.number="income.total_descuento"
                label="Total descuento:"
                :rules="mandatoryDiscountRules"
                :disabled="readonly"
                :isMandatory="mandatoryDiscountRules.length > 0"
                @input="calculateTotalSimple"
              />
              <kn-check-box
                class="mt-0"
                v-model="applyDiscount"
                label="Aplicar descuento:"
                :rules="[]"
                :disabled="readonly"
                :isMandatory="false"
              />
              <kn-text-field
                v-model.number="income.total_penalizaciones"
                label="Total penalización:"
                :rules="mandatoryPenaltyRules"
                :disabled="readonly"
                :isMandatory="mandatoryPenaltyRules.length > 0"
                @input="calculateTotalSimple" 
              />
              <kn-check-box
                class="mt-0"
                v-model="applyPenalty"
                label="Aplicar penalización:"
                :rules="[]"
                :disabled="readonly"
                :isMandatory="false"
              />
              <kn-text-field
                v-model.number="income.total_ingreso"
                label="Total:"
                :rules="[rules.required]"
                :disabled="readonly"
              />
              <kn-select
                v-model="income.id_moneda"
                label="Moneda:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="activeItems(coins)"
                item-value="id"
                item-text="nombre_moneda"
              />
              <kn-select
                v-model="income.id_forma_de_pago"
                label="Forma de pago:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="activeItems(paymentMethods)"
                item-value="id"
                item-text="dato"
              />
              <kn-doc-item
                v-if="receipt === null || (receipt !== null && !receipt.id)"
                class="mb-5"
                idInput="inputCom"
                label="Agregar comprobante:"
                :disabled="readonly"
                :isMandatory="false"
                @saveFile="saveReceipt"
                @clearFile="clearReceipt"
              />
              <kn-download-doc-item
                v-if="!isNewMode && receipt !== null && receipt.id"
                class="mb-5"
                :doc="receipt"
                label="Comprobante:"
                colBtn="4"
                :disabled="readonly"
                @deleteDoc="clearReceipt"
              />
              <kn-select
                v-model="income.id_cuenta_destino"
                label="Cuenta destino:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="activeItems(accounts)"
                item-value="id"
                item-text="nombre_cuenta"
              />
              <kn-select
                v-model="income.id_estatus"
                label="Estatus de ingreso:"
                :rules="[rules.required]"
                :disabled="readonly"
                :items="activeItems(incomeStatus)"
                item-value="id"
                item-text="dato"
              />
              <kn-text-area
                v-model.number="income.comentarios"
                label="Comentarios:"
                :rules="[]"
                :disabled="readonly"
                :isMandatory="false"
              />
              <!-- <kn-form-subtitle title="" />
              <v-row>
                <v-col
                  class="pb-0"
                  cols="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <v-checkbox
                    label="Generar factura"
                    dense
                  />
                </v-col>
              </v-row> -->
            </v-form>
            <kn-form-action-buttons
              :disableAccept="readonly"
              @accept="save"
              @cancel="cancel"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <kn-back-to-top-button />
    <kn-local-alert
      v-model="showAlert"
      :alertText="alertText" 
      :alertType="alertType"
      :alertColor="alertColor"
      :loadingAlert="loading"
      @action1="actionAlertBtn1"
      @action2="continueAdding"
    />
  </section>
</template>

<script>
import { api } from '@/api/axios-base'
import { mapState } from 'vuex'
import { apiMixin } from '@/mixins/apiMixin'
import { generalFetchingMixin } from '@/mixins/generalFetchingMixin'
import { dateUtilsMixin } from '@/mixins/dateUtilsMixin'
import { fileUtilsMixin } from '@/mixins/fileUtilsMixin'
import { utilsMixin } from '@/mixins/utilsMixin'
import { validationFormMixin } from '@/mixins/validationFormMixin'
import KnFormActionButtons from '@/components/KnFormActionButtons.vue'
// import KnFormSubtitle from '@/components/KnFormSubtitle.vue'
import KnDocItem from '@/components/forms/KnDocItem.vue'
import KnLocalAlert from '@/components/KnLocalAlert.vue'
import KnDownloadDocItem from '@/components/forms/KnDownloadDocItem.vue'
import KnFormTitle from '@/components/KnFormTitle.vue'
import KnBackToTopButton from '@/components/forms/KnBackToTopButton.vue'
import KnTextField from '@/components/inputs/KnTextField.vue'
import KnSelect from '@/components/inputs/KnSelect.vue'
import KnCheckBox from '@/components/inputs/KnCheckBox.vue'
import KnFormNoteOfMandatory from '@/components/KnFormNoteOfMandatory.vue'
import KnTextArea from '@/components/inputs/KnTextArea.vue'
export default {
  components: {
    // KnFormSubtitle,
    KnFormActionButtons,
    KnDocItem,
    KnLocalAlert,
    KnDownloadDocItem,
    KnFormTitle,
    KnBackToTopButton,
    KnTextField,
    KnSelect,
    KnCheckBox,
    KnFormNoteOfMandatory,
    KnTextArea,
  },
  mixins: [
    apiMixin,
    generalFetchingMixin,
    dateUtilsMixin,
    fileUtilsMixin,
    validationFormMixin,
    utilsMixin
  ],
  props: {
    entity: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      valueDeterminate: 100,
      routerName: 'Ingresos',
      valid: true,
      income: {
        id_alumno: null,
        id_adeudo: null,
        id_categoria: null,
        costo_envio: 0.00,
        total_ingreso: 0.00, // se va a calcular de la suma de subtotal + impuestos menos el valor de descuento
        descuento_aplicado: false,
        id_moneda: 1,
        id_estatus: 1,
        id_autor: null, // debe usar el de usuario logueado
        total_impuestos: 0.00,
        total_descuento: 0.00,
        total_penalizaciones: 0.00,
        penaliacion_aplicada: false,
        id_penalizacion: 1,
        id_institucion_educativa: null,
        comentarios: ''
      },
      applyTaxes: false,
      applyPenalty: false,
      applyDiscount: false,
      incomeId: null,
      students: [],
      institutions: [],
      accounts: [],
      paymentMethods: [],
      incomeCategories: [],
      coins: [],
      incomeStatus: [],
      showDebts: false,
      studentDebts: [],
      debtSelected: {},
      debtsSelected: [],
      debt: {},
      penaltyAmount: 0.00,
      penalty_total: 0.00,
      discountAmount: 0.00,
      debtStatus: [],
      statusDebt: 1,
      /** Variables para alerta */
      errors: [],
      warnings: [],
      loading: false,
      showAlert: false,
      alertType: 'success',
      alertText: 'Registro exitoso',
      alertColor: null,
      /*********************** */
      incomeDate: null,
      receipt: null,
      receiptIdToDelete: null,
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData']),
    title() {
      return this.entity === null ? 'Registro de ingresos' : 'Detalle de ingresos'
    },
    penaltyPercent() {
      return `${this.penaltyAmount * 100}%`
    },
    discountPercent() {
      return `${this.discountAmount * 100}%`
    },
    isNewMode() {
      return (this.entity === null)
    },
    successAlertText() {
      return this.isNewMode ? 'Ingreso registrado con exito!' : 'Ingreso actualizado con exito!'
    },
    successAlertType() {
      return this.isNewMode ? 'success' : 'info'
    },
    debtText() {
      return this.isNewMode 
        ? 'Adeudos pendientes de pago'
        : this.hasDebts 
          ? 'Adeudos relacionados' 
          : 'Adeudos pendientes de pago'
    },
    hasDebts() {
      return this.isNewMode 
        ? this.debtsSelected !== null && this.debtsSelected.length > 0 
        : (this.entity.adeudo !== null && this.entity.adeudo.length > 0) 
          || (this.debtsSelected !== null && this.debtsSelected.length > 0)
    },
    mandatoryDiscountRules() {
      return this.applyDiscount ? [this.rules.required] : []
    },
    mandatoryPenaltyRules() {
      return this.applyPenalty ? [this.rules.required] : []
    },
    mandatoryTaxesRules() {
      return this.applyTaxes ? [this.rules.required] : []
    },
    taxSuffix() {
      return this.applyTaxes ? '16%' : '0%'
    }
  },
  watch: {
    applyPenalty() {
      this.calculateTotalSimple()
    },
    applyDiscount() {
      this.calculateTotalSimple()
    }
  },
  async created() {
    this.loading = true
    this.alertText = 'Cargando... Por favor, espera'
    this.alertType = 'info'
    this.showAlert = true

    this.income.id_institucion_educativa = this.institutionId
    this.income.id_autor = this.userData.id
    this.students = await this.fetchResultsByEI('personas', 'alumno', this.institutionId)
    this.incomeCategories = await this.fetchResultsByEI('administracion', 'categoria-ingreso', this.institutionId)
    this.paymentMethods = await this.fetchResultsByEI('administracion', 'forma-de-pago', this.institutionId)
    this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
    this.accounts = await this.fetchResultsByEI('administracion', 'cuanta', this.institutionId)
    this.institutions = await this.fetchResultsAll('personas', 'institucion-educativa-all')
    this.debtStatus = await this.fetchResultsByEI('administracion', 'estatus-adeudos', this.institutionId)
    this.incomeStatus = await this.fetchResultsByEI('administracion', 'estatus-ingreso', this.institutionId)

    const today = new Date()
    this.incomeDate = this.getDate(today)
    // console.log('hoy:', this.getDate(today));

    if (!this.isNewMode) {
      // console.log('Se deben llenar los campos');
      this.incomeId = this.entity.id
      this.fillIncome()
    }
    this.showAlert = false
  },
  methods: {
    async postIncome() {
      try {
        const response = await this.postObj('/administracion/crear-ingreso', this.income)
        // console.log('Respuesta ingreso:', response);
        if (response.error) {
          this.errors.push('No se pudo realizar el registro del ingreso')
        } else {
          this.incomeId = response.id_objeto
        }
      } catch (error) {
        console.error('Error a crear ingreso.', error);
      }
    },
    async updateIncome() {
      try {
        const response = await this.postObj('/administracion/update-ingreso', this.income, 'actualizar')
        // console.log('Respuesta actualizar ingreso:', response);
        if (response.error) {
          this.errors.push('No se pudo actualizar el ingreso')
        }
      } catch (error) {
        console.error('Error a actualizar ingreso.', error);
      }
    },
    /**
     * Actualiza los adeudos de alumno seleccionados
     */
    async updateDebts() {
      try {
        // Diferenciamos los adeudos que se van a agregar
        const debtsAdded = this.isNewMode 
                            ? [...this.debtsSelected]
                            : this.debtsSelected
                              .filter(idSelected => !this.entity.adeudo.includes(idSelected))
        // Diferenciamos los adeudos que se van a remover del ingreso
        const debtsRemoved = this.isNewMode 
                              ? [] 
                              : this.entity.adeudo
                                .filter(idSelected => !this.debtsSelected.includes(idSelected))
        // console.log('Diferencia de adeudos', debtsAdded);
        for (const debtId of debtsAdded) {
          // console.log('Actualizando debt', debtId);
          const debtSelected = this.studentDebts.find(debt => debt.id === debtId)
          const timeStatus = this.statusItem(debtSelected)
          const debtStatusId = this.findDebtStatusId(timeStatus, this.applyPenalty)
          const debt = {
            id: debtSelected.id,
            nombre_adeudo: debtSelected.nombre_adeudo,
            id_alumno: debtSelected.alumno.id,
            comentarios: debtSelected.comentarios,
            sub_total: debtSelected.sub_total,
            total_impuestos: debtSelected.total_impuestos,
            total_descuento:  debtSelected.total_descuento,
            total_adeudo: debtSelected.total_adeudo,
            descuento_aplicado: debtSelected.descuento_aplicado,
            id_moneda: debtSelected.moneda.id,
            id_estatus: debtStatusId,
            expirado: debtSelected.expirado,
            fecha_vencimiento: debtSelected.fecha_vencimiento,
            fecha_pronto_pago: debtSelected.fecha_pronto_pago,
            id_penalizacion: debtSelected.penalizacion.id,
            id_descuento_pronto_pago: debtSelected.descuento_pronto_pago.id,
            id_institucion_educativa: this.institutionId
          }

          const response = await this.postObj('/administracion/update-adeudos', debt, 'actualizar')
          // console.log('Respuesta actualizar adeudo: ', response);
          if (response.error) {
            this.errors.push('No se pudo actualizar el adeudo')
          }
        }
        // Si los adeudos se quitan del ingreso
        // entonces se debe de devolver el estatus
        // Pendiente de pago al adeudo
        // console.log('Adeudos a remover', debtsRemoved);
        for (const debtToRemove of debtsRemoved) {
          // console.log('Removiendo debt', debtToRemove);
          const debtSelected = this.studentDebts.find(debt => debt.id === debtToRemove.id)
          // console.log('debtSelected', debtSelected);
          const debtStatusId = this.findDebtStatusId(this.status.NOVALID, this.applyPenalty)
          const debt = {
            id: debtSelected.id,
            nombre_adeudo: debtSelected.nombre_adeudo,
            id_alumno: debtSelected.alumno.id,
            comentarios: debtSelected.comentarios,
            sub_total: debtSelected.sub_total,
            total_impuestos: debtSelected.total_impuestos,
            total_descuento:  debtSelected.total_descuento,
            total_adeudo: debtSelected.total_adeudo,
            descuento_aplicado: debtSelected.descuento_aplicado,
            id_moneda: debtSelected.moneda.id,
            id_estatus: debtStatusId,
            expirado: debtSelected.expirado,
            fecha_vencimiento: debtSelected.fecha_vencimiento,
            fecha_pronto_pago: debtSelected.fecha_pronto_pago,
            id_penalizacion: debtSelected.penalizacion.id,
            id_descuento_pronto_pago: debtSelected.descuento_pronto_pago.id,
            id_institucion_educativa: this.institutionId
          }

          const response = await this.postObj('/administracion/update-adeudos', debt, 'actualizar')
          // console.log('Respuesta actualizar adeudo: ', response);
          if (response.error) {
            this.errors.push('No se pudo actualizar el adeudo')
          }
        }
      } catch (error) {
        this.errors.push('Ocurrió un error al intentar actualizar estatus de adeudo de alumno')
        console.error('Error al actualizar adeudo de alumno.', error);
      }
    },
    /**
     * Se obtienen los adeudos correspondientes al alumno seleccionado.
     * Se van a mostrar solo los que esten pendientes de pago
     */
    async getDebts(debtStatusStr = 'pendiente de pago') {
      try {
        // console.log('Dentro de getDebts', debtStatusStr);
        this.clearQty()
        this.showDebts = true
        const debts = await this.fetchResultsWithParams(
          'administracion',
          'aedudos-by-alumno',
          { id_alumno: this.income.id_alumno }
        )
        // console.log('Debts obtenidos', debts);
        if (debtStatusStr) {
          // console.log('if debtStatus', debtStatusStr);
          this.studentDebts = debts.filter(d => d.estatus.dato.toLowerCase().includes(debtStatusStr))
        } else {
          // console.log('else debtStatus', debtStatusStr);
          this.studentDebts = [...debts]
        }
        console.log('Resultado getDebts', this.studentDebts);
      } catch (error) {
        console.error('Error al obtener los adeudos del alumno.', error);
      }
    },
    filterDebtsByCategory() {
      // console.log('filterDebtsByCategory id:', this.income.id_categoria);
      const category = this.incomeCategories.find(cat => cat.id === this.income.id_categoria)
      // console.log('Categoria seleccionada:', category);
      if (category) {
        const debtsFiltered = this.studentDebts.filter(sd => category.dato.toLowerCase().includes(sd.comentarios.toLowerCase()) )
        // console.log('debtsFiltered:', debtsFiltered);
        if (debtsFiltered) {
          this.studentDebts = [...debtsFiltered]
        }
      }
    },
    colorItem(item) {
      const todayFull = new Date()
      const today = this.getDateWithTimeZero(todayFull)
      const pronto = this.getDateWithTimeZeroStr(item.fecha_pronto_pago)
      const vencimiento = this.getDateWithTimeZeroStr(item.fecha_vencimiento)
      this.statusDebt = this.getTimeStatus(today, pronto, vencimiento)
      return this.getTimeColor(today, pronto, vencimiento)
    },
    statusItem(item) {
      const todayFull = new Date()
      const today = this.getDateWithTimeZero(todayFull)
      const pronto = this.getDateWithTimeZeroStr(item.fecha_pronto_pago)
      const vencimiento = this.getDateWithTimeZeroStr(item.fecha_vencimiento)
      return this.getTimeStatus(today, pronto, vencimiento)
    },
    /**
     * Busca el id de estatus de adeudo correspondiente
     * al estatus de tiempo del adeudo. 
     * EARLYPAY, PROMPTPAY, INTIME devuelven el 
     * id de estatus que corresponda al estatus 'Pagado'.
     * Mientras que OVERDUE devuelve el id del estatus
     * 'Pagado con penalizacion' pero solo si el
     * usuario indica que se debe aplicar la
     * penalizacion de otra manera tambien devuelve
     * el id de estatus 'Pagado'
     * @param {number} timeStatus Estatus de tiempo
     * @param {boolean} applyPenalty Indicador de si se va aplicar penalizacion o no
     * @return {number} id de estatus de adeudo  
     */
    findDebtStatusId(timeStatus, applyPenalty = false) {
      let statusStr = 'Pendiente de pago'
      switch(timeStatus) {
        case this.status.EARLYPAY:
        case this.status.INTIME:
        case this.status.PROMPTPAY:
          statusStr = 'Pagado'
          break
        case this.status.OVERDUE:
          statusStr = applyPenalty ? 'Pagado con penalizacion' : 'Pagado'
          break
        default: statusStr = 'Pendiente de pago'
      }
      const status = this.debtStatus.find(s => s.dato.toLowerCase().includes(statusStr.toLowerCase()))
      return status.id
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.alertText = 'Cargando...'
        this.showAlert = true

        this.income.descuento_aplicado = this.applyDiscount
        this.income.penaliacion_aplicada = this.applyPenalty
        this.income.penalizacion_aplicada = this.applyPenalty
        // console.log('que tiene debtsSelected?', this.debtsSelected, );
        this.income.id_adeudo = this.hasDebts ? [...this.debtsSelected] : null
        // console.log('tiene adeudos?', this.hasDebts, 'Ids adeudos', this.income.id_adeudo);
        if (this.isNewMode) {
          await this.postIncome()
          if (this.errors.length === 0 && this.hasDebts) {
            // console.log('Se deberian actualizar los adeudos');
            await this.updateDebts()
          }
          if (this.errors.length === 0 && this.receipt !== null) {
            await this.createReceiptDocument()
          }
          if (this.errors.length === 0 && this.income.id_alumno !== null) {
            await this.sendProofOfPayment()
          }
        } else {
          await this.updateIncome()
          if (this.errors.length === 0) {
            await this.correctAccountBalance()
          }
          if (this.errors.length === 0 && this.hasDebts) {
            await this.updateDebts()
          }
          if (this.errors.length === 0) {
            if (this.receiptIdToDelete !== null) {
              await this.removeReceiptFromIncome(this.receiptIdToDelete, this.incomeId)
              await this.deleteReceiptPermanently(this.receiptIdToDelete)
            }
            if (this.receipt !== null && this.receipt.file) {
              await this.createReceiptDocument()
            }
            if (this.errors.length === 0 && this.income.id_alumno !== null) {
            await this.sendProofOfPayment()
          }
          }
        }
        this.loading = false
        if (this.errors.length) {
          this.alertType = 'error'
          this.alertText = this.errors.join(', ')
        } else {
          this.alertType = this.successAlertType
          this.alertColor = 'success'
          this.alertText = this.successAlertText
        }
      }
    },
    cancel() {
      this.$router.push({name: this.routerName})
    },
    fillDebtValues() {
      // console.log('Adeudos seleccionado fillDebtValues: ', this.debtsSelected);
      let subtotal = 0.0
      let impuestos = 0.0
      let descuento = 0.0
      let total = 0.0
      this.debtsSelected.forEach(debtId => {
        const debtSelected = this.studentDebts.find(debt => debt.id === debtId)
        subtotal += parseFloat(debtSelected.sub_total)
        impuestos += parseFloat(debtSelected.total_impuestos)
        descuento += parseFloat(debtSelected.total_descuento)
        total += parseFloat(debtSelected.total_adeudo)
      });
      this.income.sub_total = subtotal
      this.income.total_impuestos = impuestos
      this.income.total_descuento = descuento
      this.income.total_ingreso = total
      // //this.income.id_adeudo = this.debt.id
      
      // this.debt = {
      //   id: this.debtSelected.id,
      //   id_alumno: this.debtSelected.alumno.id,
      //   sub_total: this.debtSelected.sub_total,
      //   total_impuestos: this.debtSelected.total_impuestos,
      //   total_descuento: this.debtSelected.total_descuento,
      //   total_adeudo: this.debtSelected.total_adeudo,
      //   descuento_aplicado: this.debtSelected.descuento_aplicado,
      //   id_moneda: this.debtSelected.moneda.id,
      //   expirado: this.debtSelected.expirado,
      //   fecha_vencimiento: this.debtSelected.fecha_vencimiento,
      //   fecha_pronto_pago: this.debtSelected.fecha_pronto_pago,
      //   id_penalizacion: this.debtSelected.penalizacion.id,
      //   id_descuento_pronto_pago: this.debtSelected.descuento_pronto_pago.id,
      //   id_institucion_educativa: this.debtSelected.institucion_educativa.id,
      //   id_estatus: this.debtSelected.estatus.id,
      //   id_autor: this.userData.id,
      //   comentarios: this.debtSelected.comentarios
      // }

      // this.discountAmount = parseFloat(this.debtSelected.descuento_pronto_pago.porcentaje_descuento)

      // let {
      //   sub_total: subtotal,
      //   total_impuestos: impuestos,
      //   total_descuento: descuento,
      //   total_adeudo: total,
      //   descuento_aplicado: applyDiscount
      // } = this.debt
      // let penalizacion = 0.00
      
      // subtotal = parseFloat(subtotal)
      // impuestos = parseFloat(impuestos)
      // descuento = parseFloat(descuento)
      // total = parseFloat(total)

      // this.income.id_adeudo = this.debt.id
      // this.income.sub_total = subtotal
      // this.income.total_impuestos = impuestos
      // this.income.total_descuento = this.discountAmount ? subtotal * this.discountAmount : descuento
      // this.income.total_ingreso = total

      // this.statusDebt = this.statusItem(this.debt)
      // // console.log('estatusDebt', this.statusDebt);
      // if (this.statusDebt === this.status.OVERDUE) {
      //   this.penaltyAmount = parseFloat(this.debtSelected.penalizacion.monto)
      //   penalizacion = this.penaltyAmount
      //   this.income.total_penalizaciones = penalizacion
      //   this.income.penaliacion_aplicada = true
      //   this.income.penalizacion_aplicada = true
      //   this.income.total_ingreso = (subtotal + penalizacion + impuestos) - (applyDiscount ? descuento : 0.00)
      // } else {
      //   this.penaltyAmount = 0.00
      //   this.income.total_penalizaciones = 0.00
      //   this.income.penaliacion_aplicada = false
      //   this.income.penalizacion_aplicada = false
      // }
      
    },
    clearQty() {
      this.debtsSelected = []
      this.studentDebts = []
      this.income.sub_total = 0.00
      this.income.total_impuestos = 0.00
      this.income.total_descuento = 0.00
      this.income.total_ingreso = 0.00
      this.income.total_penalizaciones = 0.00
      this.income.penaliacion_aplicada = false

      this.penaltyAmount = 0.00
    },
    calculateTotalSimple() {
      const {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_penalizaciones: penalizacion
      } = this.income
      
      const total = (subtotal + (this.applyTaxes ? impuestos : 0.00) + (this.applyPenalty ? penalizacion : 0.00)) - (this.applyDiscount ? descuento : 0.00)
      this.income.total_ingreso = total
    },
    calculateTotal() {
      this.income.total_impuestos = this.income.sub_total * (this.applyTaxes ? 0.16 : 0.00)

      let {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_ingreso: total,
      } = this.income
      let penalizacion = 0.00
      impuestos = subtotal * (this.applyTaxes ? 0.16 : 0.00)
      total = (subtotal + impuestos) - descuento
      this.income.total_impuestos = impuestos
      this.income.total_ingreso = total

      if (this.debtSelected) {
        this.statusDebt = this.statusItem(this.debt)
        // console.log('estatusDebt', this.statusDebt);
        if (this.statusDebt === this.status.OVERDUE) {
          this.penaltyAmount = parseFloat(this.debtSelected.penalizacion.monto)
          penalizacion = this.penaltyAmount
          this.income.total_penalizaciones = penalizacion
          this.income.penaliacion_aplicada = true
          this.income.penalizacion_aplicada = true
          this.income.total_ingreso = (subtotal + penalizacion + impuestos) - descuento
        } else {
          this.penaltyAmount = 0.00
          this.income.total_penalizaciones = 0.00
          this.income.penaliacion_aplicada = false
          this.income.penalizacion_aplicada = false
        }
      }
    },
    actionAlertBtn1() {
      if (this.alertType === 'success' || this.alertType  === 'info') {
        this.returnToTable()
      } else {
        this.closeAlert()
      }
    },
    continueAdding() {
      this.clean()
      this.closeAlert()
    },
    returnToTable() {
      this.$router.replace({name: this.routerName})
    },
    closeAlert() {
      this.errors = []
      this.showAlert = false
    },
    clean() {
      this.income = {
        id_alumno: null,
        id_adeudo: null,
        id_categoria: null,
        sub_total: 0.00,
        total_impuestos: 0.00,
        costo_envio: 0.00,
        total_descuento: 0.00,
        total_penalizaciones: 0.00,
        total_ingreso: 0.00, // se va a calcular de la suma de subtotal + impuestos menos el valor de descuento
        descuento_aplicado: false,
        penalizacion_aplicada: false,
        id_penalizacion: null,
        id_forma_de_pago: null,
        id_moneda: 1,
        id_cuenta_destino: null,
        id_estatus: null,
        id_autor: this.userData.id, // debe usar el de usuario logueado
      }
    },
    /**
     * Llena el formulario con los datos del ingreso
     * seleccionado para editar. Los valores se toman
     * de entity que es objeto que se pasa de la tabla
     * de ingresos a este formulario.
     */
    async fillIncome() {
      this.incomeDate = this.getDateWithTimeZeroFullStr(this.entity.fecha_elaboracion)

      const {
        sub_total: subtotal,
        total_impuestos: impuestos,
        total_descuento: descuento,
        total_ingreso: total,
        total_penalizaciones: penalizaciones,
      } = this.entity

      // console.log('Llenando income');
      // console.log('Entity:', this.entity);
      // console.log('subtotal:', subtotal, 'impuestos:', impuestos, 'descuento:', descuento, 'penalizaciones:', penalizaciones);
      if (this.entity.alumno) {
        this.income.id_alumno = this.entity.alumno.id
        await this.getDebts(null)
        // console.log('showDebts', this.showDebts);
        // console.log('allStudentDebts', this.studentDebts);
        // console.log('Tiene adeudos?', this.hasDebts);
        if (this.hasDebts) {
          this.debtsSelected = this.entity.adeudo.map(debt => debt.id)
        }
      }
      this.applyPenalty = this.entity.penalizacion_aplicada
      this.applyDiscount = this.entity.descuento_aplicado

      this.income = {
        id: this.entity.id,
        id_alumno: this.entity.alumno ? this.entity.alumno.id : null,
        id_adeudo: this.hasDebts ? [...this.debtsSelected] : null,
        id_categoria: this.entity.categoria.id,
        sub_total: parseFloat(subtotal),
        total_impuestos: parseFloat(impuestos),
        costo_envio: this.entity.costo_envio,
        total_descuento: parseFloat(descuento),
        total_penalizaciones: parseFloat(penalizaciones),
        total_ingreso: parseFloat(total), // se va a calcular de la suma de subtotal + impuestos menos el valor de descuento
        descuento_aplicado: this.applyDiscount,
        penalizacion_aplicada: this.applyPenalty,
        comentarios: this.entity.comentarios,
        id_penalizacion: this.entity.penalizacion ? this.entity.penalizacion.id : null,
        id_forma_de_pago: this.entity.forma_de_pago.id,
        id_moneda: this.entity.moneda.id,
        id_cuenta_destino: this.entity.cuenta_destino.id,
        id_estatus: this.entity.estatus.id,
        id_autor: this.userData.id, // debe usar el de usuario logueado
        id_institucion_educativa: this.institutionId
      }
      // console.log('income llenada', this.income);
      //await this.getDebts()

      this.receipt = this.entity.comprobantes.length > 0 ? this.entity.comprobantes[0] : null
      // this.showDebts = this.hasDebts
    },
    /** Funciones para manejar comprobantes */
    saveReceipt(file) {
      this.receipt = file
    },
    clearReceipt(file) {
      this.receipt = null
      if (!this.isNewMode && file.id !== null) {
        this.receiptIdToDelete = file.id
      }
    },
    async assignReceiptToIncome(receiptId, incomeId) {
      try {
        const response = await api.post('/administracion/agregar-comprobante-ingreso', {
          id_ingreso: incomeId,
          id_comprobante: receiptId
        })
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar asignar comprobante a ingreso', error);
      }
    },
    async removeReceiptFromIncome(receiptId, incomeId) {
      try {
        const response = await api.post('/administracion/remove-comprobante-ingreso', {
          id_ingreso: incomeId,
          id_comprobante: receiptId
        })
        const responseData = await response.data
        return responseData
      } catch (error) {
        console.error('Error al intentar remover el comprobante de ingreso', error);
      }
    },
    async createReceiptDocument() {
      /** Para agregar un comprobante al ingreso se 
       * realizan los siguientes pasos:
       * - Subir archivo
       * - Crear documento comprobante
       * - Agregar comprobante ingreso
       * 
       * Ya que agregar comprobante necesita id de
       * comprobante e id de ingreso se debe llamar
       * este método despues de crear el ingreso
       */
      const responseUpload = await this.uploadFile(this.receipt)
      if (responseUpload && responseUpload.mensaje.includes('Archivo guardado con éxito')) {
        const { nombre_archivo, url_archivo } = responseUpload.archivos[0]
        const docObj = {
          nombre_documento: nombre_archivo,
          url_documento: url_archivo,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }
        const responsePostDoc = await this.postReceiptDoc(docObj)

        if (responsePostDoc && responsePostDoc.mensaje.includes('operación exitosa')) {
          const receiptId = responsePostDoc.id_objeto
          const responseAssign = await this.assignReceiptToIncome(receiptId, this.incomeId)

          if (responseAssign && responseAssign.mensaje.includes('operación exitosa')) {
            this.alertText = 'Comprobante asignado exitosamente'
          } else {
            this.errors.push('No se pudo asignar el comprobante de ingreso')
          }
        }
      }
    },
    async sendProofOfPayment() {
      const incomeStatusSelected = this.incomeStatus.find(iStatus => iStatus.id === this.income.id_estatus)
      const validStatus = ['pagado', 'completado', 'completo']
      if (validStatus.some(vStatus => incomeStatusSelected.dato.toLowerCase().includes(vStatus))) {
        const response = await this.postObj('/administracion/enviar-comprobante-ingreso', {
          id_ingreso: this.incomeId
        })
        if (response.error) {
          this.warnings.push('No se pudo enviar el comprobante de pago')
        } else {
          this.alertText = 'Comprobante de pago enviado por correo'
        }
      }
    },
    /**
     * Incrementa balance de cuenta y crea movimiento de cuenta
     * @param {object} objToIncrease Objeto con los datos para incrementar balance
     */
    async increaseAccountBalance(objToIncrease) {
      try {
        const response = await this.postObj('/administracion/aumentar-balance-cuenta', objToIncrease)
        if (response.error) {
          this.errors.push('No se pudo incrementar balance de cuenta: ' + response.error)
        } else {
          this.alertText = 'Balance incrementado con éxito'
        }
      } catch (error) {
        this.alertText = 'Ocurrió un error al intentar incrementar balance de cuenta'
      }
    },
    /**
     * Decrementa balance de cuenta y crea movimiento de cuenta
     * @param {object} objToDecrease Objeto con los datos para decrementar balance
     */
    async decreaseAccountBalance(objToDecrease) {
      try {
        const response = await this.postObj('/administracion/disminuir-balance-cuenta', objToDecrease)
        if (response.error) {
          this.errors.push('No se pudo decrementar balance de cuenta: ' + response.error)
        } else {
          this.alertText = 'Balance decrementado con éxito'
        }
      } catch (error) {
        this.alertText = 'Ocurrió un error al intentar decrementar balance de cuenta'
      }
    },
    /**
     * Corrección de balance de cuenta. Toma el monto anterior
     * y lo resta al balance, luego toma el monto actualizado
     * y lo suma al balance.
     */
    async correctAccountBalance() {
      const amountToCorrect = parseFloat(this.entity.total_ingreso)
      const correctedAmount = this.income.total_ingreso
      const objForMovement = {
        id_cuenta: this.income.id_cuenta_destino,
        monto: amountToCorrect,
        id_autor: this.userData.id,
        comentarios: `Salida por corrección de ingreso (ID ${this.incomeId})`
      }
      await this.decreaseAccountBalance(objForMovement)

      objForMovement.monto = correctedAmount
      objForMovement.comentarios = `Entrada por corrección de ingreso (ID ${this.incomeId})`
      await this.increaseAccountBalance(objForMovement)

    },
    activeItems(array) {
      return array.filter(item => item.estatus_sistema === true)
    }
  }
}
</script>

<style>

</style>